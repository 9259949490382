@import "colors";
@import "typography";
@import "break-widths";
@import "buttons";
@import "material-theme";

html, body {
    margin: 0;
    min-width: $wpx-small;
    color: $outerspace;
    height: 100%;
    scroll-behavior: smooth;
    
    font-size: 8px;
    @media (min-width: $wpx-small)  { font-size: 9px; }
    @media (min-width: $wpx-phone8) { font-size: 10px; }
    @media (min-width: $wpx-phone)  { font-size: 12px; }
    @media (min-width: $wpx-ipad)   { font-size: 14px; }
    @media (min-width: $wpx-large)  { font-size: 16px; }
    @media (min-width: $wpx-xlarge) { font-size: 19px; }
}

.mscroll {
    mix-blend-mode: normal;
}

.kaapi-logo {
    width: 8em;
    height: 8em;
    border-radius: 1em;

    padding: 2em;
    color: white;
    background-color: $kaapiteal;
    box-sizing: border-box;
}

.spacer {
    flex-grow: 1;
    align-self: stretch;
}

section { 
    margin: 5em 3em; 
    @media (min-width: $wpx-ipad) {
        section { margin: 8em 4em; }
    }
}