// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,900;1,200;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400&display=swap');
@import "break-widths";

html, body {
    // font-family: 'Roboto', sans-serif;
    // font-family: 'Nunito', sans-serif;
    font-family: 'Lato', sans-serif;
}

*::selection {
    background-color: $kaapiteal;
    color: white;
}

h1 {
    font-size: 4rem;
    font-weight: 300;
}

h2 {
    font-size: 3.2rem;
    line-height: 1.5;
    font-weight: 300;

    @media (min-width: $wpx-ipad) {
        font-size: 2.4rem;
    }
}

h3 {
    font-size: 2.1rem;
    line-height: 1.6;
    font-weight: 300;

    @media (min-width: $wpx-ipad) {
        font-size: 1.8rem;
    }
}

.justify {
    text-align: justify;
}

.center {
    text-align: center;
}

.title {
    font-size: 2.4em;
    font-weight: 300;
}

p {
    font-weight: 300;
    line-height: 1.75;
    font-size: 1.6em;

    @media (min-width: $wpx-ipad) {
        font-size: 1.1em;
        &.larger {
            font-size: 1.2em;
        }
    }    
}

.subtitle {
    font-weight: bold;
    font-size: 1em;
    line-height: 1.8em;
    
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    letter-spacing: 0.13em;
    text-transform: uppercase;
}

// links, router-links
*[ng-reflect-router-link]:focus {
    outline: none;
}

a {
    text-underline-offset: 0.2em;
    text-decoration-color: $kaapiteal;
    text-decoration-thickness: 0.2em;

    &:visited, &:active, &:link {
        color: inherit;
    }

    &:hover {
        text-decoration-thickness: 0.4em;
        text-underline-offset: 0.1em;
    }
}

.underline, u {
    text-decoration: none; 
    position: relative; 

    &:after { 
        position: absolute; 
        content: ''; 
        height: 0.2em; 
        bottom: -0.3em;

        margin: 0 auto;
        left: 0;
        right: 0;
        width:100%; 
        background: $kaapiteal; 
        border-radius: 0.5em;
    }
}


.line-left {
    position: relative; 
    &:after { 
        position: absolute; 
        content: ''; 
        width: 0.5em; 
        left: -1.5em;

        margin: 0 auto;
        top: 0;
        bottom: 0;
        height: 100%; 
        background: $kaapiteal; 
        // border-radius: 0.5em;
    }
}