@import "colors";

.kbutton {
    display: inline-block;
    padding: 1.2em 2em;
    background-color: $kaapiteal;
    color: white;
    font-weight: bold;
    letter-spacing: 0.1em;
    border-radius: 0.2em;
    border: 0.1em solid transparent;
    font-size: 1.2rem;

    &:hover {
        background-color: transparent;
        border: 0.1em solid $kaapiteal;
        color: $kaapiteal;
    }

    transition: all 150ms ease-out; 
    cursor: pointer;

    &.white-bkg {
        border: 1px white solid;
    }
    &:hover.white-bkg {
        background-color: white;
    }
}


@media (min-width: $wpx-ipad) {
    .kbutton {
        font-size: 1rem;
    }
}