/* For use in src/lib/core/theming/_palette.scss */
$md-kaapipallete: (
    50 : #f8ffff,
    100 : #bbe8e5,
    200 : #8feae6,
    300 : #4dddd6,
    400 : #26d6cd,
    500 : #00cfc4,
    600 : #00cabf,
    700 : #00c3be,
    800 : #00bcbd,
    900 : #00acb2,
    A100 : #ffffff,
    A200 : #fff,
    A400 : #fff,
    A700 : #5cfcff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$kaapi-primary: mat-palette($md-kaapipallete, A400);
$kaapi-accent: mat-palette($md-kaapipallete, A100, A200, A400);
$kaapi-warn: mat-palette($mat-red, 800); 

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$kaapi-dark-theme: mat-dark-theme((
  color: (
    primary: $kaapi-primary,
    accent: $kaapi-accent,
    warn: $kaapi-warn,
  )
));

.dark-theme {
    @include angular-material-theme($kaapi-dark-theme);
}
